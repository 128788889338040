import BasicService from "./BasicService";

class BillService extends BasicService {
  constructor() {
    super();
  }

  getCashTypes() {
    let url = `/bill/cashtype`;
    return this.axios.get(url).then(response => response.data);
  }
  getBranch() {
    let url = `/userAccounts/branch`;
    return this.axios.get(url).then(response => response.data);
  }
  getIncomeTypes() {
    let url = `/ie/income/incometype`;
    return this.axios.get(url).then(response => response.data);
  }
  getExpenseTypes() {
    let url = `/ie/expense/expensetype`;
    return this.axios.get(url).then(response => response.data);
  }
  saveCash(cashtype) {
    let url = `/bill/cashtype`;
    return this.axios.post(url,cashtype).then(response => response.data);
  }
  saveIncomeIncome(income) {
    let url = `/ie/income`;
    return this.axios.post(url,income).then(response => response.data);
  }
  updateIncomeIncome(income) {
    let url = `/ie/income/${income.incomeId}`;
    return this.axios.put(url,income).then(response => response.data);
  }
  deleteIncomeIncome(income) {
    let url = `/ie/income/${income.incomeId}`;
    return this.axios.delete(url).then(response => response.data);
  }
  updateCash(cashtype) {
    let url = `/bill/cashtype/${cashtype.typeId}`;
    return this.axios.put(url,cashtype).then(response => response.data);
  }
  deleteCash(cashtype) {
    let url = `/bill/cashtype/${cashtype.typeId}`;
    return this.axios.delete(url).then(response => response.data);
  }
  saveIncome(income) {
    let url = `/ie/income/incometype`;
    return this.axios.post(url,income).then(response => response.data);
  }
  updateIncome(income) {
    let url = `/ie/income/incometype/${income.itypeId}`;
    return this.axios.put(url,income).then(response => response.data);
  }
  deleteIncome(income) {
    let url = `/ie/income/incometype/${income.itypeId}`;
    return this.axios.delete(url).then(response => response.data);
  }
  saveExpense(expense) {
    let url = `/ie/expense/expensetype`;
    return this.axios.post(url,expense).then(response => response.data);
  }
  updateExpense(expense) {
    let url = `/ie/expense/expensetype/${expense.etypeId}`;
    return this.axios.put(url,expense).then(response => response.data);
  }
  deleteExpense(expense) {
    let url = `/ie/expense/expensetype/${expense.etypeId}`;
    return this.axios.delete(url).then(response => response.data);
  }
  saveBill(bill) {
    let url = `/bill`;
    return this.axios.post(url,bill).then(response => response.data);
  }
  updateBill(bill) {
    let url = `/bill/${bill.billId}`;
    return this.axios.put(url,bill).then(response => response.data);
  }
  billDownload(fromDate, toDate,typeId) {
    return this.axios({
      url: "/bill/excel",
      method: "GET",
      responseType: "blob",
      params: {
        fromDate,
        toDate,
        typeId
      } // important
    });
  }
  getBillLists(fromDate,toDate,typeId,branchId) {
    let url = `/bill`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate,typeId,branchId
      }
    }).then(response => response.data);
  }
  deleteBill(bill) {
    let url = `/bill/${bill.billId}`;
    return this.axios.delete(url).then(response => response.data);
  }
  billImport(formData){
    let url = `/bill/import`;
    return this.axios.post(url, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(request => request.data);
  }
  getIncome(fromDate,toDate,itypeId,typeId,branchId) {
    let url = `/ie/income`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate,itypeId,typeId,branchId
      }
    }).then(response => response.data);
  }
  getExpense(fromDate,toDate,etypeId,typeId,branchId) {
    let url = `/ie/expense`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate,etypeId,typeId,branchId
      }
    }).then(response => response.data);
  }
  saveExpenseExpense(expense) {
    let url = `/ie/expense`;
    return this.axios.post(url,expense).then(response => response.data);
  }
  updateExpenseExpense(expense) {
    let url = `/ie/expense/${expense.expenseId}`;
    return this.axios.put(url,expense).then(response => response.data);
  }
  deleteExpenseExpense(expense) {
    let url = `/ie/expense/${expense.expenseId}`;
    return this.axios.delete(url).then(response => response.data);
  }
  getBalance(typeId) {
    let url = `/bill/balance/${typeId}`;
    return this.axios.get(url).then(response => response.data);
  }
  getBalanceLeft() {
    let url = `/bill/balance`;
    return this.axios.get(url).then(response => response.data);
  }
  getBalanceDetail(fromDate,toDate,typeId) {
    let url = `/bill/balance/detail/${typeId}`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate
      }
    }).then(response => response.data);
  }
}
const airlineService = new BillService();
export default airlineService;
